import { useReducer, useState, useEffect } from "react";
import { Box } from "@mui/material";
import InputButton from "components/common/Forms/FormButton";
import PaymenHistory from "./PaymentsHistory";
import PendingPayments from "./PendingPayments";
import {
  initialState,
  paymentsReducer,
} from "context/payments/paymentsReducer";
import { startGetAllPayments } from "actions/payments";
import { types } from "context/types/types";
import Spinner from "components/common/Spinner";
import PaymentDetails from "./PaymentDetails";
import * as XLSX from "xlsx";
import { formatDate } from "utils/formatDate";

const Payments = () => {
  //Estado que controla el historial de pagos y los pagos pendientes
  const [optionDisplay, setOptionDisplay] = useState(false);

  const [loading, setLoading] = useState(false);
  //reducer de pagos
  const [payments, dispatch] = useReducer(paymentsReducer, initialState);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const getAllPayments = async () => {
      dispatch({ type: types.paymentStartFetch, payload: {} });
      const body = await startGetAllPayments();
      if (!body.statusCode) {
        dispatch({ type: types.paymentFetch, payload: body });
      } else {
        dispatch({ type: types.paymentError, payload: body });
      }
    };
    getAllPayments();
    setFlag(false);
  }, [flag]);

  const titulo = [{ A: "Reporte de Pagos" }, {}];

  const longitudes = [5, 35, 25, 20, 10, 10, 10];

  const handleDownload = () => {
    setLoading(true);

    let tabla = [
      {
        A: "Id",
        B: "Nombre ",
        C: "Fecha",
        D: "Monto",
        E: "Estado",
        F: "Parcial",
      },
    ];

    payments.payments.forEach((producto) => {
      tabla.push({
        A: producto.payment.id,
        B: `${producto.payment.user.firstName} ${producto.payment.user.lastName}`,
        C: formatDate(producto.payment.createdAt),
        D: producto.payment.amount,
        E:
          producto.payment.paymentStatus === "PENDING"
            ? "Pendiente"
            : "Aprobado",
        F: producto.payment.isPartial ? "Sí" : "No",
      });
    });

    const dataFinal = [...titulo, ...tabla];

    setTimeout(() => {
      creandoArchivo(dataFinal);
      setLoading(false);
    }, 1000);
  };

  const creandoArchivo = (dataFinal) => {
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

    hoja["!merges"] = [
      XLSX.utils.decode_range("A1:G1"),
      XLSX.utils.decode_range("A2:G2"),
      XLSX.utils.decode_range("A34:G34"),
    ];

    let propiedades = [];

    longitudes.forEach((col) => {
      propiedades.push({
        width: col,
      });
    });

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, "Pagos");

    XLSX.writeFile(libro, "Pagos.xlsx");
  };

  if (payments.loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      {/* Contenedor superior de los botones */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0rem 1rem",
          backgorundColor: "red",
        }}
      >
        <InputButton
          text="Historial de pago"
          onClick={() => setOptionDisplay(false)}
        />
        <PaymentDetails />
        <InputButton
          text="Pagos Pendientes"
          onClick={() => setOptionDisplay(true)}
        />
        <InputButton
          disabled={loading}
          text="Descargar Pagos"
          onClick={() => {
            handleDownload();
          }}
        />
      </Box>

      {/* Contenedor del contenido de la pagina, ya sea historial o Pagos pendientes */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {optionDisplay === null && <span>Seleccione una opcion</span>}
        {optionDisplay === false && (
          <PaymenHistory
            setFlag={setFlag}
            dispatch={dispatch}
            payments={payments.payments}
          />
        )}
        {optionDisplay === true && (
          <PendingPayments
            setFlag={setFlag}
            dispatch={dispatch}
            payments={payments.payments}
          />
        )}
      </Box>
    </>
  );
};

export default Payments;
